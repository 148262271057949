import React, { useState } from "react";

const Requestform = () => {
  const initialFormData = {
    boatName: "",
    length: "",
    width: "",
    tonnage: "",
    draft: "",
    horsepower: "",
    rpm: "",
    gearRatio: "",
    motorModel: "",
    motorCount: "",
    propellerGap: "",
    shaftThickness: "",
    hullType: "sac",
    phone: "",
    personOrCompany: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.boatName) newErrors.boatName = "Tekne ismi zorunludur.";
    if (!formData.phone) newErrors.phone = "Telefon numarası zorunludur.";
    if (!formData.email) newErrors.email = "E-posta adresi zorunludur.";
    if (!formData.personOrCompany)
      newErrors.personOrCompany = "Kişi veya firma bilgisi zorunludur.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSuccessMessage("");
    setErrorMessage("");

    if (!validateForm()) {
      setErrorMessage("Lütfen gerekli alanları doldurun.");
      return;
    }

    const queryParams = Object.keys(formData)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
    )
    .join("&") + "&pdf=true"; // Burada `pdf=true` eklendi

    const xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        setSuccessMessage(
          "Form başarıyla gönderildi! En kısa sürede size geri dönüş yapılacaktır."
        );
        setFormData(initialFormData);
      } else {
        setErrorMessage("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
      }
    });

    xhr.addEventListener("error", () => {
      setErrorMessage(
        "Bağlantı hatası oluştu. Lütfen internet bağlantınızı kontrol edin."
      );
    });

    xhr.open(
      "GET",
      `https://www.hdsmarin.com/sendemail/indexsip.php?${queryParams}`
    );
    xhr.send();
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center py-10 pt-4 bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg w-full space-y-6 top-28 absolute"
      >
        <h1 className="text-3xl font-bold text-center text-[#212C42] mb-6">
          Pervane Sipariş Formu
        </h1>

        {successMessage && (
          <div className="mb-4 p-4 text-green-700 bg-green-100 border border-green-500 rounded">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-500 rounded">
            {errorMessage}
          </div>
        )}

        <div className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Kişisel Bilgiler</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Tekne İsmi</label>
                <input
                  type="text"
                  name="boatName"
                  value={formData.boatName}
                  onChange={handleInputChange}
                  className={`w-full p-2 border rounded ${
                    errors.boatName ? "border-red-500" : "border-gray-300"
                  } focus:outline-none focus:ring focus:ring-blue-300`}
                />
                {errors.boatName && (
                  <p className="text-sm text-red-500 mt-1">{errors.boatName}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Telefon Numarası</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className={`w-full p-2 border rounded ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  } focus:outline-none focus:ring focus:ring-blue-300`}
                />
                {errors.phone && (
                  <p className="text-sm text-red-500 mt-1">{errors.phone}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Kişi / Firma</label>
                <input
                  type="text"
                  name="personOrCompany"
                  value={formData.personOrCompany}
                  onChange={handleInputChange}
                  className={`w-full p-2 border rounded ${
                    errors.personOrCompany ? "border-red-500" : "border-gray-300"
                  } focus:outline-none focus:ring focus:ring-blue-300`}
                />
                {errors.personOrCompany && (
                  <p className="text-sm text-red-500 mt-1">{errors.personOrCompany}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">E-posta</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={`w-full p-2 border rounded ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } focus:outline-none focus:ring focus:ring-blue-300`}
                />
                {errors.email && (
                  <p className="text-sm text-red-500 mt-1">{errors.email}</p>
                )}
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Tekne Bilgileri</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Boy (m)</label>
                <input
                  type="number"
                  name="length"
                  value={formData.length}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">En (m)</label>
                <input
                  type="number"
                  name="width"
                  value={formData.width}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Gros Ton</label>
                <input
                  type="number"
                  name="tonnage"
                  value={formData.tonnage}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Draft (m)</label>
                <input
                  type="number"
                  name="draft"
                  step="0.01"
                  value={formData.draft}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Motor Bilgileri</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Beygir Gücü (HP)</label>
                <input
                  type="number"
                  name="horsepower"
                  value={formData.horsepower}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Devir (RPM)</label>
                <input
                  type="number"
                  name="rpm"
                  value={formData.rpm}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Şanzıman Oranı</label>
                <input
                  type="text"
                  name="gearRatio"
                  value={formData.gearRatio}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Motor Modeli</label>
                <input
                  type="text"
                  name="motorModel"
                  value={formData.motorModel}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Motor Adedi</label>
                <input
                  type="number"
                  name="motorCount"
                  value={formData.motorCount}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Pervane Boşluğu (cm)</label>
                <input
                  type="number"
                  name="propellerGap"
                  value={formData.propellerGap}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Şaft Kalınlığı (mm)</label>
                <input
                  type="number"
                  name="shaftThickness"
                  value={formData.shaftThickness}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Gövde Tipi</label>
                <select
                  name="hullType"
                  value={formData.hullType}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                >
                  <option value="sac">Sac</option>
                  <option value="fiberglass">Fiberglass</option>
                  <option value="wood">Ahşap</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full p-3 bg-[#212C42]   text-white font-bold rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Gönder
        </button>
      </form>
    </div>
  );
};

export default Requestform;
