import React from "react";

import SpecTable from "../Reuse/SpecTable";

const Pwaterlock = () => {
  const specDataw1 = [
    { fields: ["B", "230", "230", "230"] },
    { fields: ["C", "145", "145", "145"] },
    { fields: ["D", "540", "540", "540"] },
    { fields: ["HACİM", "8lt", "8lt", "8lt"] },
  ];

  const specDataw2 = [
    { fields: ["B", "230", "230", "230"] },
    { fields: ["C", "145", "145", "145"] },
    { fields: ["D", "540", "540", "540"] },
    { fields: ["HACİM", "8lt", "8lt", "8lt"] },
  ];

  const specDataw3 = [
    { fields: ["B", "230", "230", "230"] },
    { fields: ["C", "145", "145", "145"] },
    { fields: ["D", "540", "540", "540"] },
    { fields: ["HACİM", "8lt", "8lt", "8lt"] },
  ];

  return (
    <div className="bg-gray-200 w-screen py-8 absolute top-20">
      {/* Başlık */}
      <div className="text-center mb-8">
        <h1 className="text-[4vmax] font-black tracking-wider text-[#212C42]">
          WATERLOCK
        </h1>
      </div>

      {/* Grid Yapısı */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {/* Ürün 1 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/waterlock w1.webp"
            alt="Waterlock W1"
            className="rounded-md w-full  object-contain mb-4"
          />
          <SpecTable
            cols={["A", "38-45mm", "40-50mm", "60mm"]}
            specData={specDataw1}
            title="WATERLOCK W1"
          />
        </div>

        {/* Ürün 2 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/waterlock w2.webp"
            alt="Waterlock W2"
            className="rounded-md w-full  object-contain mb-4"
          />
          <SpecTable
            cols={["A", "38-45mm", "40-50mm", "60mm"]}
            specData={specDataw2}
            title="WATERLOCK W2"
          />
        </div>

        {/* Ürün 3 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/waterlock.webp"
            alt="Waterlock W3"
            className="rounded-md w-full  object-contain mb-4"
          />
          <SpecTable
            cols={["A", "38-45mm", "40-50mm", "60mm"]}
            specData={specDataw3}
            title="WATERLOCK W3"
          />
        </div>
      </div>
    </div>
  );
};

export default Pwaterlock;
