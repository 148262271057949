import React from "react";

export default function SpecTable({ cols, specData, title }) {
  return (
    <div className="ComponentTableImg  overflow-hidden  mx-auto">
      {/* Table */}
      <div className="  text-xs md:text-lg mx-auto  shadow-lg rounded-sm  border-gray-200">
        <header className="px-5 flex items-center justify-center h-8 md:h-10 border-b bg-[#212C42] border-gray-100">
          <h2 className="text-center  font-bold  text-white">
       {title?title :"ÜRÜN ÖLÇÜ TABLOSU"}
          </h2>
        </header>
        <div className="">
          <div className="">
            <table className="table-auto w-full">
              <thead className="text-s font-semibold  text-gray-400 bg-gray-50">
                <tr>
                  {cols.map((item, i) => (
                    <th className="py-2 whitespace-nowrap" key={i}>
                      <div className="font-semibold text-center">{item}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-xs md:text-sm divide-y divide-gray-100">
                {specData.map((item, i) => (
                  <tr key={i}>
                    {specData[i].fields.map((field, c) => (
                      <td className="p-2 whitespace-nowrap" key={c}>
                        <div className="font-medium text-center">{field}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
