import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Pekaplin = () => {
  const tableData = [
    {
      no: 1,
      desc: "GÖVDE",
      brand: "HASÇELİKLER",
      material: "TPU",
      count: "1",
    },
    {
      no: 2,
      desc: "CİVATA",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "8",
    },
    {
      no: 3,
      desc: "LAMA",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },

  ];



  const specData = [

      {
        fields: ["50x78","105","78","50","40","M10","4"],    
      },
      {
        fields: ["60x80","115","80","60","30","M10","4"],    
      },
      {
        fields: ["63x82","115","82,55","63,5","30","M10","4"],    
      },
      {
        fields: ["65x95","120","95","65","30","M10","6"],    
      },
      {
        fields: ["65x90","135","90","65","30","M10","6"],    
      },
      {
        fields: ["65x100","140","100","65","50","M10","4"],    
      },
      {
        fields: ["75x103","140","103","75","50","M10","6"],    
      },
      {
        fields: ["63x108","160","107,95","63,5","50","M10","4"],    
      },
      {
        fields: ["63x108","160","107,95","63,5","50","7/16","4"],    
      },
      {
        fields: ["76x120","160","120,6","76,2","50","1/2","6"],    
      },


      {
        fields: ["76x120","160","120,6","76,2","50","M14","6"],    
      },
      {
        fields: ["76x120","160","120,6","76,2","50","M16","6"],    
      },
      {
        fields: ["90x131","160","131","90","50","M12","8"],    
      },
      {
        fields: ["95x152","190","152,4","95,25","60","5/8","6"],    
      },
      {
        fields: ["120x156","190","156","120","60","M16","6"],    
      },
      {
        fields: ["127x222","276","222,2","127","60"," 1″","8"],    
      },
      {
        fields: ["140x170","210","170","140","60"," M16-M18","10"],    
      },
      {
        fields: ["140x196","229","196","140","60","M16","8"],    
      },
      {
        fields: ["140x218","270","218","140","60","M20","10"],    
      },
      {
        fields: ["152x190","230","190,5","152,4","60","M20","8"],    
      },
    ]  

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        ESNEK KAPLİN
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/Esnek kaplin.webp"
          alt="Esnek Kaplin"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-4 my-16 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/esnek kaplin teknik.webp"
          alt="Esnek Kaplin"
          className=""
        />
      </div>
      <div className="desc lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="ekaplinDesc"/>
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start py-4">
        <SpecTable  cols={["TİP", "A", " B", " E", " H", "CİVATA","CİVATA ADEDİ"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Pekaplin;
