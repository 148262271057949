import React from "react";

import SpecTable from "../Reuse/SpecTable";

const Pdenizsuyufiltresi = () => {
  const specDataw1 = [
    { fields: ["3/4”", "3/4”"] },
    { fields: ["1”", "1”"] },
    { fields: ["1,1/4”", "1,1/4”"] },
    { fields: ["1,1/2”", "1,1/2”"] },
    { fields: ["2”", "2”"] },
  ];

  const specDataw2 = [
    { fields: ["3/4”", "3/4”"] },
    { fields: ["1”", "1”"] },
    { fields: ["1,1/4”", "1,1/4”"] },
    { fields: ["1,1/2”", "1,1/2”"] },
    { fields: ["2”", "2”"] },
  ];

  const specDataw3 = [
    { fields: ["3/4”", "3/4”"] },
    { fields: ["1”", "1”"] },
    { fields: ["1,1/4”", "1,1/4”"] },
    { fields: ["1,1/2”", "1,1/2”"] },
    { fields: ["2”", "2”"] },
  ];

  const specDataw4 = [
    { fields: ["3/4”", "3/4”"] },
    { fields: ["1”", "1”"] },
    { fields: ["1,1/4”", "1,1/4”"] },
    { fields: ["1,1/2”", "1,1/2”"] },
    { fields: ["2”", "2”"] },
    { fields: ["2,1/2”", "2,1/2”"] },
  ];

  return (
    <div className="bg-gray-200 w-screen py-8  absolute top-20">
      <div className="text-center mb-8">
        <h1 className="text-[4vmax] font-black tracking-wider text-[#212C42]">
          DENİZ SUYU FİLTRESİ
        </h1>
      </div>

      {/* Grid Yapısı */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {/* Ürün 1 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/Filtre.webp"
            alt="Yan-Yan Deniz Suyu Filtresi"
            className="rounded-md w-full  object-contain mb-4"
          />
          <SpecTable
            cols={["INPUT", "OUTPUT"]}
            specData={specDataw1}
            title="YAN-YAN DENİZ SUYU FİLTRESİ"
          />
        </div>

        {/* Ürün 2 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/altyan filtre.webp"
            alt="Alt-Yan Deniz Suyu Filtresi"
            className="rounded-md w-full object-contain mb-4"
          />
          <SpecTable
                   cols={["INPUT", "OUTPUT"]}
            specData={specDataw2}
            title="ALT-YAN DENİZ SUYU FİLTRESİ"
          />
        </div>

        {/* Ürün 3 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/alt alt filtre.webp"
            alt="Alt-Alt Deniz Suyu Filtresi"
            className="rounded-md w-full  object-contain mb-4"
          />
          <SpecTable
                   cols={["INPUT", "OUTPUT"]}
            specData={specDataw3}
            title="ALT-ALT DENİZ SUYU FİLTRESİ"
          />
        </div>

        {/* Ürün 4 */}
        <div className="bg-white p-1 rounded-lg shadow-md">
          <img
            src="/img/products/bronz filtre.webp"
            alt="Bronz Deniz Suyu Filtresi"
            className="rounded-md w-full object-contain mb-4"
          />
          <SpecTable
               cols={["INPUT", "OUTPUT"]}
            specData={specDataw4}
            title="BRONZ DENİZ SUYU FİLTRESİ"
          />
        </div>
      </div>
    </div>
  );
};

export default Pdenizsuyufiltresi;
