import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";

const Pcelikcekmeboru = () => {

  const descData = [
    {
      title: "Sızdırmazlık",
      desc: "Ahşap teknelerde kovan borusu teknenin gövdesine krom civatalarla bağlanır.",
    },
    {
      title: "Koruyucu",
      desc: "Kovan borusunun uzun ömürlü olması için et kalınlığı 4mm ve üzeri çekme paslanmaz çelik borular tercih edilir.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Teknenin gövdesine kolay montaj yapılması için bronz yataklar geniş flanşlı olarak dökülüp hazırlanır.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Şaft yatakları aşındığında bronz yataklar kovan borusundan sökülerek değiştirilebilir.",
    },
  ];

  const specData = [

    {
      fields: ["33,7","3"],    
    },
    {
      fields: ["38,1","4"],    
    },
    {
      fields: ["42,4","4"],    
    },
    {
      fields: ["48,26","5"],    
    },
    {
      fields: ["50","4"],    
    },
    {
      fields: ["55","6"],    
    },
    {
      fields: ["60,3"," 3"],    
    },
    {
      fields: ["60,3"," 5,5"],    
    },
    {
      fields: ["66","6"],    
    },
    {
      fields: ["70","6"],    
    },
    {
      fields: ["73","10"],    
    },
    {
      fields: ["76,1","3"],    
    },
    {
      fields: ["76,1","5"],    
    },
    {
      fields: ["76,1","10"],    
    },
    {
      fields: ["80","6"],    
    },
    {
      fields: ["80","10"],    
    },
    {
      fields: ["88,9"," 3"],    
    },
    {
      fields: ["88,9","6"],    
    },
    {
      fields: ["101,6","3"],    
    },
    {
      fields: ["101,6","6"],    
    },
    {
      fields: ["101,6","12"],    
    },
    {
      fields: ["114,3","3"],    
    },
    {
      fields: ["114,3","6"],    
    },
    {
      fields: ["127","3"],    
    },
    {
      fields: ["127","12"],    
    },
    {
      fields: ["139","12"],    
    },
    {
      fields: ["152","3"],    
    },
    {
      fields: ["204","3"],    
    },
  ]

  const specData2 = [

    {
      fields: ["33,7","3"],    
    },
    {
      fields: ["38,1","3"],    
    },
    {
      fields: ["60,3","3"],    
    },
    {
      fields: ["76,1","3"],    
    },
    {
      fields: ["88,9","3"],    
    },
    {
      fields: ["101,6","3"],    
    },
    {
      fields: ["114,3","3"],    
    },
  ]  
  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20 flex justify-center  bg-gray-200 ">
      <div className="lg:w-[60vw] flex flex-wrap items-start justify-around">
      <div className="text-[4vmax] px-8 font-black tracking-wider text-[#212C42]   flex items-end w-screen  pt-8">
        ÇELİK ÇEKME BORU
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  mix-blend-multiply">
        <img
          src="/img/products/celik cekme boru.webp"
          alt="paslanmaz celik cekme boru"
          className=""
        />
      </div>

<div className="flex flex-row gap-12">
      <div className="desc  flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
<SpecTable  cols={["DIŞ ÇAP", "ET KALINLIĞI"]} specData={specData} />
      </div>
      <div className="desc  flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
        <SpecTable  cols={["DIŞ ÇAP", "ET KALINLIĞI"]} specData={specData2} />
      </div>
      </div>
</div>
    </div>
    
  );
};

export default Pcelikcekmeboru;
