import { Environment} from "@react-three/drei";
import Shaftmodel from "./Shaftmodel.jsx";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Perf } from "r3f-perf";

gsap.registerPlugin(ScrollTrigger);



export default function Experience() {


  //     <Perf position="top-left" />

  return (
    <>
     
      <Shaftmodel  pervaneRotation={0.4} />

      <directionalLight position={[3.3, 1.0, 4.4]} castShadow intensity={1} />
      <Environment files="/img/empty_warehouse_01_1k.hdr" />
    </>
  );
}
