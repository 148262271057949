import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Psaftyatak = () => {
  const tableData = [
    {
      no: 1,
      desc: "GÖVDE",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 2,
      desc: "KELEPÇE",
      brand: "HASÇELİKLER",
      material: "PASLANMAZ ÇELİK",
      count: "1",
    },
    {
      no: 3,
      desc: "HORTUM AĞZI",
      brand: "HASÇELİKLER",
      material: "PİRİNÇ",
      count: "1",
    },

  ];



    const specData = [

      {
        fields: ["25","40","1 1/21","38,1","100"],    
      },
      {
        fields: ["28","42","1 5/8”","41,27","112"],    
      },
      {
        fields: ["30","45","1 3/4","44,45","120"],    
      },
      {
        fields: ["32","45","1 3/4","44,45","128"],    
      },
      {
        fields: ["35","48","1 7/8","47,62","140"],    
      },
      {
        fields: ["38","50","2","50,8","152"],    
      },
      {
        fields: ["38","55","2 1/8","53,97","152"],    
      },
      {
        fields: ["40","55","2 1/8","53,97","160"],    
      },
      {
        fields: ["45","60","2 3/8","60,32","178"],    
      },
      {
        fields: ["45","65","2 1/2","63,5","178"],    
      },
      {
        fields: ["50","70","2 5/8","66,67","200"],    
      },
      {
        fields: ["55","75","3","76,2","220"],    
      },
      {
        fields: ["60","80","3 1/4","82,55","240"],    
      },
      {
        fields: ["65","85","3 1/2","88,9","260"],    
      },
      {
        fields: ["70","90","3 1/2","88,9","280"],    
      },
      {
        fields: ["70","95","3 3/4","95,25","280"],    
      },
      {
        fields: ["75","95","3 3/4","95,25","300"],    
      },
      {
        fields: ["75","100","4","101,6","300"],    
      },
      {
        fields: ["80","100","4","101,6","320"],    
      },
      {
        fields: ["85","105","4 1/8","104,77","340"],    
      },
      {
        fields: ["85","110","4 1/4","107,95","340"],    
      },
      {
        fields: ["90","110","4 1/2","114,3","360"],    
      },
      {
        fields: ["95","115","4 1/2","114,3","380"],    
      },
      {
        fields: ["100","125","5","127","400"],    
      },   
      {
        fields: ["100","130","5 3/8","136,52","400"],    
      },  
      {
        fields: ["105","130","5 1/4","133,35","420"],    
      },  
      {
        fields: ["110","135","5 3/8","136,52","440"],    
      },  
      {
        fields: ["110","140","5 1/2","139,7","440"],    
      },  
      {
        fields: ["115","145","5 1/2","13,7","460"],    
      },  
      {
        fields: ["120","155","6","152,4","480"],    
      },  
      {
        fields: ["125","165","6 1/2","165,1","500"],    
      },  
      {
        fields: ["130","170","6 5/8","167,27","520"],    
      }, 
      {
        fields: ["140","180","7","177,8","560"],    
      }, 
      {
        fields: ["150","190","7 1/2","190,5","6000"],    
      }, 
    ]  


  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        ŞAFT YATAĞI
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/Saft yatagı.webp"
          alt="Şaft Yatağı"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-8 my-16 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/saft yatagı teknik.webp"
          alt="Şaft Yatağı"
          className=""
        />
      </div>
      <div className="desc lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="saftyatakDesc"/>
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start py-4">
        <SpecTable  cols={["A(mm)", "B(mm)", "B(inch)", "B(mm)", "C"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Psaftyatak;
