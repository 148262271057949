/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect,  useRef, useState } from "react";
import {
  Html,
  Resize,
  useBounds,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { Tooltip } from "flowbite-react";
import { FaRegCircleDot } from "react-icons/fa6";
import "./shaftmodel.css";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

import gsap from "gsap";

export default function Shaftmodel(props) {
  const previousFloatObject = useRef(null); // floatObject'in önceki değerini saklar

  const { nodes, materials } = useGLTF("./sactekne_low.glb");
  const shaftMaterial = useTexture({
    map: "./img/1833745442352_Flat_baseColor.png",
    //  aoMap: "./img/sac_AO_0000.png",
    // roughnessMap: "./img/sac_Ro_0000.png",
    // iorMap:"./img/sac_RflF_0000.png"
  });

  shaftMaterial.metalness = 1;
  //shaftMaterial.reflectivity=1;
  shaftMaterial.map.flipY = false;
  //shaftMaterial.aoMap.flipY = false;
  //shaftMaterial.roughnessMap.flipY = false;
  //shaftMaterial.iorMap.flipY = false;

  const { scene, camera } = useThree();
  const bounds = useBounds();

  const containerRef = useRef(null);
  const sceneRef = useRef(scene);
  const cameraRef = useRef(camera);
  const tla = useRef();
  const annoRef = useRef(".anno");
  const heroRef = useRef(".hero-section");
  const pervaneRef = useRef(".pervane-section");
  const saftRef = useRef(".saft-section");
  const korukRef=useRef(".koruk-section");
  const kovanRef=useRef(".kovan-section");
  const skaplinRef=useRef(".skaplin-section");
  const ekaplin=useRef(".ekaplin-section");
  const animFadeinRef = useRef(".anim-fadein");
  const pervaneSliderRef = useRef(".pervane-slider");
  const [floatObject, setFloatObject] = useState(null);
  const [floatRange, setFloatRange] = useState(0);

  const viewport = useThree((state) => state.viewport);


  const [lastWidth, setLastWidth] =useState(viewport.width)
  //console.log(viewport.width);
  /*
  useGSAP(()=>{
    gsap.to('.asd',{opacity:0})
  
  },shaftModelRef)
 */


  useFrame((state) => {
    const t = state.clock.getElapsedTime();
  
    if (floatObject !== null) {
      // Obje mevcut mu kontrol et
      const object = sceneRef.current?.getObjectByName(floatObject);
  
      if (!object) {
        console.warn(`${floatObject} not found in the scene.`);
        return; // Obje yoksa işlem yapmadan çık
      }
  
      // Eğer floatObject değiştiyse veya yeni bir hareket başlıyorsa
      if (previousFloatObject.current !== floatObject) {
        //console.log(`${floatObject} float etmeye başladı.`);
        previousFloatObject.current = floatObject; // Yeni değeri kaydet
      }
  
      // Eğer floatObject EKAPLIN ise
      if (floatObject === "EKAPLIN") {
        object.rotation.x = THREE.MathUtils.lerp(object.rotation.x, floatRange * (Math.cos(t / 2) / 2), 0.1);
        object.rotation.y = THREE.MathUtils.lerp(object.rotation.y, floatRange * (Math.sin(t / 2) / 2), 0.1);
        object.rotation.z = THREE.MathUtils.lerp(object.rotation.z, floatRange * (Math.sin(t / 2) / 2), 0.1);
      }
  
      // Eğer floatObject SKAPLIN veya SAFTKORUK ise
      if (floatObject === "SKAPLIN" || floatObject === "SAFTKORUK") {
        object.rotation.z = THREE.MathUtils.lerp(object.rotation.z, floatRange * (Math.sin(t / 2) / 2), 0.1);
      }
    }
  });
  
  

  function handleFloat(objectName) {
    setFloatRange(1);
    setFloatObject(objectName);
  }
  function handleStopFloat() {
    setFloatRange(0);
  }

  function handleHideObjects(objectName) {
    sceneRef.current.traverse(function (child) {
      if (child instanceof THREE.Mesh && child.name !== objectName) {
        child.visible = false;
      }
    });
  }

  function handleShowObjects(objectName) {
    sceneRef.current.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.visible = true;
      }
    });
  }

  function handleExplodeObjects() {
    gsap.to(sceneRef.getObjectByName("PERVANE").position, {});
  }

  useEffect(() => {
    //---------------------------------TIMELINES-----------------------------
    let ctx = gsap.context(() => {
      const tlPer = gsap.timeline({
        scrollTrigger: {
          trigger: ".App",
          start: "top  0",
          end: "+=5000",
          toggleActions: "play complete restart reverse",
          scrub: 1,
          /*   snap: {
            snapTo:[0,0.33,1],
            delay:0,
            duration:{min: .2, max: 1},
            ease:"power1.inOut"
            
          },
         */
          pin: true,
          pinSpacing: true,
          immediateRender: false,
          ease: "power2.inOut",
          duration: 4,
        },
      });

      tlPer
        .to(sceneRef.current.rotation, { y: -Math.PI / 2, duration: 2, onComplete:()=>{tlPer.pause=true} })
        .to(
          cameraRef.current.position,
          { x: 0, y:  -0.3,z:2.5},
          "<"
        )
        .from(heroRef.current, { scaleX: 0 }, "<")
        .from(annoRef.current, { autoAlpha: 0, duration: 1 }, "-=1.5")
        .from(".hero-table-container", { yPercent: +100 }, "<")

        //.to(".hero-logo-container", { opacity: 0, duration: 3 }, "-=4")
        //.to("html", { backgroundColor: "#232C41", duration: 1 }, "-=3")  uygulamayi yavaslatiyor

        .to(
          sceneRef.current.rotation,
          { y: 0, x: Math.PI / 2, duration: 2 },
          "+=1"
        )
        .to(
          sceneRef.current.position,
          { y:0.55, duration: 2 },
          "-=0.5"
        )
        .to(
          cameraRef.current.position,
          { x:0,y: 0,z :1.2},
          "<"
        )

        .to(heroRef.current, { yPercent: 100, duration: 2 }, "-=2")
        .from(pervaneRef.current, { yPercent: -150, duration: 2 }, "<")
        .to(annoRef.current, { autoAlpha: 0 }, "-=2")
        .to(".home-hds-logo",{autoAlpha:0},"<")

        
       .from(pervaneSliderRef.current, { x: "+=450%", duration: 7 })
       .from(animFadeinRef.current, { opacity: 0, stagger: 0.2, duration:0.7 },"<")

        .to(
          scene.getObjectByName("PERVANE").rotation,
          { z: 15, duration: 7 },
          "-=7"
        )

        .to(sceneRef.current.position, {
          x: -0.3,
          y: 0,
          duration: 2,
        })
        .to(cameraRef.current.position, { x: 0, y: 0,z:2.2, duration: 2 }, "<")
        .to(pervaneRef.current, { yPercent: 100, duration: 2 }, "<")
        /* .to(
          scene.getObjectByName("MIL001"),
          {
            visible: true,
            onUpdate: () => {
              handleExplodeObjects();
            },
            onReverseComplete: () => {
              handleShowObjects("MIL001");
            },
          },
          "<"
        )
        */
        .to(
          sceneRef.current.getObjectByName("PERVANE").position,
          { x: "-=10", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("KOVAN").position,
          { z: "+=75", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("SAFTKORUK").position,
          { z: "+=95", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("SKAPLIN").position,
          { z: "+=98", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("EKAPLIN").position,
          { z: "+=114", duration: 3 },
          "<"
        )
        .from(saftRef.current, { xPercent: 130, duration: 3 }, "<")

        .fromTo(
          ".bg-section",
          { scaleY: 0 },
          { scaleY: 0.6, backgroundColor: "#212C42", duration: 2 },
          "-=2"
        )

        //kovan
        .from(kovanRef.current, { xPercent: 130, duration: 3 },"+=1")
        .to(saftRef.current,{opacity:0,duration:2},"<")

        .to(
          sceneRef.current.position,
          { y: -0.7, duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("KISA_KOVAN_2").position,
          { z: "+=38", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("KISA_KOVAN").position,
          { z: "-=13", duration: 3 },
          "<"
        )
        .to(
          ".bg-section",
          { scaleY: 0.5, backgroundColor: "#212C42", duration: 2 },
          "<"
        )

        //koruk
        .from(korukRef.current, { xPercent: 130, duration: 3 },"+=1")
        .to(kovanRef.current,{opacity:0,duration:2},"<")
        .to(
          sceneRef.current.position,
          {   
            x:-0.075,          
         y:-1.1,
          
         
          duration: 2, 
          onComplete: () => {
            handleFloat("SAFTKORUK");
          },
          onReverseComplete:()=>{
            handleStopFloat();
          }
        },
          "<"
        )
        .to(cameraRef.current.position,{z:0.6,duration:2},"<")
        .to(
          sceneRef.current.getObjectByName("KISA_KOVAN_2").position,
          { z: "-=38", duration: 3 },
          "<"
        )
        .to(
          sceneRef.current.getObjectByName("KISA_KOVAN").position,
          { z: "+=13", duration: 3 },
          "<"
        )
        .to(
          ".bg-section",
          { scaleY: 0.5, backgroundColor: "#212C42", duration: 2 },
          "<"
        )
        

        // Sikmali kaplin
        .from(skaplinRef.current, { xPercent: 130, duration: 3 },"+=1")
        .to(korukRef.current,{opacity:0,duration:2},"<")
        .to(
          sceneRef.current.position,
          {
            x:-0.08,          
            y:-1.31,
            duration: 2,
            onComplete: () => {
              handleFloat("SKAPLIN");
            },
            onReverseComplete:()=>{
              handleStopFloat();
            }
          },
          "<"
        )
        .to(cameraRef.current.position,{z:0.8,duration:2},"<")


        //Esnek kaplin
        .from(ekaplin.current, { xPercent: 130, duration: 3 },"+=1")
        .to(skaplinRef.current,{opacity:0,duration:2},"<")
        .to(sceneRef.current.position, {
          x:-0.08,          
          y:-1.53,
          duration:2,
          onComplete: () => {
            handleFloat("EKAPLIN");
          },
          onReverseComplete:()=>{
            handleStopFloat();
          }
        },"<");
    });

    return () => ctx.revert();
  }, []);

  return (
    <group  {...props} dispose={null}>
      <group name="Scene">
        <group
          name="RootNode"
          scale={0.01}
         // scale={viewport.width/viewport.height}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        >
          <group name="SAC_TEKNE">
            <group
              className=""
              name="PERVANE"
              position={[-33.329, 0, 0]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            >
              <Html wrapperClass="anno" position={[0, 0, 0]}>
                <Tooltip
                  className="opacity-1 visible"
                  style="light"
                  placement="bottom"
                  content="PROPELLER"
                >
                  <FaRegCircleDot />
                </Tooltip>
              </Html>

              <group
                name="P_GOVDE"
                position={[0, 0, 0.044]}
                rotation={[Math.PI, 0, 0.733]}
              >
                <mesh
                  name="P_GOVDE001"
                  castShadow
                  receiveShadow
                  geometry={nodes.P_GOVDE001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
              <group
                name="PKANAT"
                position={[0, 0, -1.706]}
                rotation={[-Math.PI / 2, -0.838, Math.PI / 2]}
              >
                <mesh
                  name="PKANAT001"
                  castShadow
                  receiveShadow
                  geometry={nodes.PKANAT001.geometry}
                  material={materials.DefaultMaterial}
                
                />
              </group>
              <group
                name="PKANAT_2"
                position={[0, 0, -1.706]}
                rotation={[Math.PI / 2, -0.733, -Math.PI / 2]}
              >
                <mesh
                  name="PKANAT_2001"
                  castShadow
                  receiveShadow
                  geometry={nodes.PKANAT_2001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
              <group
                name="PKANAT_3"
                position={[0, 0, -1.706]}
                rotation={[Math.PI / 2, 0.838, -Math.PI / 2]}
              >
                <mesh
                  name="PKANAT_3001"
                  castShadow
                  receiveShadow
                  geometry={nodes.PKANAT_3001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
              <group
                name="PKANAT_4"
                position={[0, 0, -1.706]}
                rotation={[-Math.PI / 2, 0.733, Math.PI / 2]}
              >
                <mesh
                  name="PKANAT_4001"
                  castShadow
                  receiveShadow
                  geometry={nodes.PKANAT_4001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
              <group
                name="PSOMUN"
                position={[0, 0, -2.837]}
                rotation={[Math.PI / 2, 0, Math.PI]}
              >
                <mesh
                  name="PSOMUN001"
                  castShadow
                  receiveShadow
                  geometry={nodes.PSOMUN001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
            </group>
            <group
              name="SAFT"
              position={[-16.144, 0.022, -0.03]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            >
            
              <group name="EKAPLIN" position={[0.022, -0.03, 54.149]}>
                <group
                  name="2564896982672"
                  position={[4.1, 0, 1.65]}
                  rotation={[Math.PI, 0, -2.356]}
                >
                  <group name="ǁKMA_PUL_4">
                    <group
                      name="2563976537104_4"
                      rotation={[Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896983376"
                  position={[0, 4.1, 1.65]}
                  rotation={[-Math.PI, 0, 2.356]}
                >
                  <group name="ǁKMA_PUL_5">
                    <group
                      name="2563976537104_5"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_5001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_5001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896984432"
                  position={[-2.934, -2.934, 2.8]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group name="ǁKMA_PUL_3">
                    <group
                      name="2563976537104_3"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896984784"
                  position={[0, -4.1, 1.65]}
                  rotation={[Math.PI, 0, -Math.PI / 4]}
                >
                  <group name="ǁKMA_PUL">
                    <group
                      name="2563976537104"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896984960"
                  position={[2.934, -2.934, 2.8]}
                  rotation={[Math.PI, 0, -Math.PI / 4]}
                >
                  <group name="ǁKMA_PUL_6">
                    <group
                      name="2563976537104_6"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_6001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_6001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896985136"
                  position={[2.934, 2.934, 2.8]}
                  rotation={[Math.PI, 0, -2.356]}
                >
                  <group name="ǁKMA_PUL_7">
                    <group
                      name="2563976537104_7"
                      rotation={[Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_7001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_7001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896985312"
                  position={[-2.934, 2.934, 2.8]}
                  rotation={[-Math.PI, 0, 2.356]}
                >
                  <group name="ǁKMA_PUL_8">
                    <group
                      name="2563976537104_8"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2563976537104_8001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563976537104_8001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896986016"
                  position={[-3.394, 3.394, 0.8]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group name="PUL_PARǁ_2">
                    <group name="2563679511472_2" rotation={[-Math.PI, 0, 0]}>
                      <mesh
                        name="2563679511472_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563679511472_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896987072"
                  position={[2.934, 2.934, 0.2]}
                  rotation={[0, 0, 2.297]}
                >
                  <group name="M10x35mm_AKB_CݖATA_6">
                    <group
                      name="2562676079536_6"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_6001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_6001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896987600"
                  position={[2.934, -2.934, 5.75]}
                  rotation={[-Math.PI, 0, 1.457]}
                >
                  <group name="2564141281904_3" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_3001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_3001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896987776"
                  position={[0, 4.1, 2.25]}
                  rotation={[Math.PI, 0, -2.356]}
                >
                  <group name="M10x35mm_AKB_CݖATA_4">
                    <group
                      name="2562676079536_4"
                      rotation={[Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896987952"
                  position={[-2.934, 2.934, 5.75]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group name="2564141281904_2" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_2001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_2001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896989008"
                  position={[4.1, 0, 2.55]}
                  rotation={[Math.PI, 0, -2.356]}
                >
                  <group name="M10x35mm_AKB_CݖATA_2">
                    <group
                      name="2562676079536_2"
                      rotation={[Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896989184"
                  position={[-2.934, -2.934, 0.2]}
                  rotation={[0, 0, 2.297]}
                >
                  <group name="M10x35mm_AKB_CݖATA_5">
                    <group
                      name="2562676079536_5"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_5001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_5001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896989536"
                  position={[2.934, -2.934, -0.1]}
                  rotation={[0, 0, 2.297]}
                >
                  <group name="M10x35mm_AKB_CݖATA_7">
                    <group
                      name="2562676079536_7"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_7001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_7001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896989712"
                  position={[-2.934, 2.934, -0.1]}
                  rotation={[0, 0, 2.297]}
                >
                  <group name="M10x35mm_AKB_CݖATA_8">
                    <group
                      name="2562676079536_8"
                      rotation={[-Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_8001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_8001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896989888"
                  position={[0, -4.1, 2.25]}
                  rotation={[Math.PI, 0, -2.356]}
                >
                  <group name="M10x35mm_AKB_CݖATA_3">
                    <group
                      name="2562676079536_3"
                      rotation={[Math.PI, 0, Math.PI / 4]}
                    >
                      <mesh
                        name="2562676079536_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676079536_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896990416"
                  position={[-2.934, -2.934, 6.05]}
                  rotation={[-Math.PI, 0, 0.998]}
                >
                  <group name="2564141281904" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896990592"
                  position={[2.934, 2.934, 6.05]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group name="2564141281904_4" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_4001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_4001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896990768"
                  position={[2.934, -2.934, 4.022]}
                  rotation={[-Math.PI, 0, 0.469]}
                >
                  <group name="M10_YAYLI_RONDELA">
                    <group name="2563933659840" rotation={[Math.PI, 0, 0.664]}>
                      <mesh
                        name="2563933659840001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896991296"
                  position={[2.934, 2.934, 4.022]}
                  rotation={[Math.PI, 0, -1.102]}
                >
                  <group name="M10_YAYLI_RONDELA_2">
                    <group
                      name="2563933659840_2"
                      rotation={[-Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896991472"
                  position={[-2.934, -2.934, 4.022]}
                  rotation={[-Math.PI, 0, 2.04]}
                >
                  <group name="M10_YAYLI_RONDELA_4">
                    <group
                      name="2563933659840_4"
                      rotation={[Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896992000"
                  position={[-2.934, 2.934, 4.022]}
                  rotation={[Math.PI, 0, -2.673]}
                >
                  <group name="M10_YAYLI_RONDELA_3">
                    <group
                      name="2563933659840_3"
                      rotation={[-Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="M10x35mm_AKB_CݖATA"
                  position={[-4.1, 0, 2.55]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group
                    name="2562676079536"
                    rotation={[-Math.PI, 0, Math.PI / 4]}
                  >
                    <mesh
                      name="2562676079536001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2562676079536001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="PUL_PARǁ"
                  position={[-4.75, 0, 1.95]}
                  rotation={[-Math.PI, 0, 0]}
                >
                  <group name="2563679511472" rotation={[Math.PI, 0, 0]}>
                    <mesh
                      name="2563679511472001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2563679511472001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="ǁKMA_PUL_2"
                  position={[-4.1, 0, 1.65]}
                  rotation={[Math.PI, 0, Math.PI / 4]}
                >
                  <group
                    name="2563976537104_2"
                    rotation={[-Math.PI, 0, Math.PI / 4]}
                  >
                    <mesh
                      name="2563976537104_2001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2563976537104_2001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group name="ށNZIMAN" position={[0, 0, 1.65]}>
                  <Html wrapperClass="anno" position={[0, 0, 0]}>
                    <Tooltip
                      className="opacity-1 visible "
                      style="light"
                      placement="top"
                      content="ESNEK KAPLİN"
                    >
                      <FaRegCircleDot />
                    </Tooltip>
                  </Html>
                  <mesh
                    name="ށNZIMAN001"
                    castShadow
                    receiveShadow
                    geometry={nodes.ށNZIMAN001.geometry}
                    material={materials.DefaultMaterial}
                  />
                </group>
              </group>

              <group name="KOVAN" position={[0.022, -0.03, -88.14]}>
                <group
                  name="DISGLEN"
                  position={[0, 0, 83.078]}
                  rotation={[0, -1.571, 0]}
                >
                  <mesh
                    name="DISGLEN001"
                    castShadow
                    receiveShadow
                    geometry={nodes.DISGLEN001.geometry}
                    material={materials.DefaultMaterial}
                  />
                </group>
                <group
                  name="IC_GLEN"
                  position={[0, 0, 115.199]}
                  rotation={[0, -1.571, 0]}
                >
                  <mesh
                    name="IC_GLEN001"
                    castShadow
                    receiveShadow
                    geometry={nodes.IC_GLEN001.geometry}
                    material={materials.DefaultMaterial}
                  />
                </group>
                <group
                  name="KBORU"
                  position={[0, 0, 115.199]}
                  rotation={[0, -1.571, 0]}
                >
                  <Html wrapperClass="anno" position={[-15, 0, -0.6]}>
                    <Tooltip
                      className="opacity-1 visible"
                      style="light"
                      placement="bottom"
                      content="ŞAFT BORUSU"
                    >
                      <FaRegCircleDot />
                    </Tooltip>
                  </Html>
                  <mesh
                    name="KBORU001"
                    castShadow
                    receiveShadow
                    geometry={nodes.KBORU001.geometry}
                    material={materials.DefaultMaterial}
                  />
                </group>
                <group
                  name="KISA_KOVAN"
                  position={[0, 0, 83.078]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="1833745451856">
                    <mesh
                      name="1833745451856001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745451856001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="KISA_KOVAN_2"
                  position={[0, 0, 83.078]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="1850088486592">
                    <mesh
                      name="1850088486592001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1850088486592001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="ر01_6_H185"
                  position={[0, 0, 110.185]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="1844936778224">
                    <mesh
                      name="1844936778224001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1844936778224001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="ر01_6_H185_2"
                  position={[0, 0, 110.185]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="1844936847056">
                    <mesh
                      name="1844936847056001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1844936847056001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
              </group>
              <group name="MIL" position={[0.022, -0.03, 37.125]}>
                <Html wrapperClass="anno" position={[0, 0.6, -45]}>
                  <Tooltip
                    className="opacity-1 visible"
                    style="light"
                    placement="top"
                    content="ŞAFT"
                  >
                    <FaRegCircleDot />
                  </Tooltip>
                </Html>
                <mesh
                  name="MIL001"
                  castShadow
                  receiveShadow
                  geometry={nodes.MIL001.geometry}
                  material={materials.DefaultMaterial}
                />
              </group>
       
              <group name="SAFTKORUK"  position={[0.022, 0.269, -67.788]} >
              
                <group
                  name="1841171068592"
                  position={[0, -0.299, 96.661]}
                  rotation={[-Math.PI / 2, -0.09, 0]}
                >
                  <Html wrapperClass="anno" position={[0, -2, 0.5]}>
                    <Tooltip
                      className="opacity-1 visible mb-8"
                      style="light"
                      placement="top"
                      content="ŞAFT KÖRÜĞÜ"
                    >
                      <FaRegCircleDot color="white" />
                    </Tooltip>
                  </Html>
                  <group name="Kelep_2">
                    <group name="1833745462368_2">
                      <mesh
                        name="1833745462368_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["1833745462368_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
              
                <group
                  name="1841171071056"
                  position={[0, -0.299, 100.889]}
                  rotation={[-Math.PI / 2, 0, 0]}
                >
                  <group name="1851583767840_2">
                    <mesh
                      name="1851583767840_2001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583767840_2001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                  <group name="1851583769568_2">
                    <mesh
                      name="1851583769568_2001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583769568_2001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                  <group name="1851583770720_2">
                    <mesh
                      name="1851583770720_2001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583770720_2001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="1841171073872"
                  position={[0, -0.299, 99.171]}
                  rotation={[Math.PI / 2, -0.002, 0]}
                >
                  <group name="O_ring_2">
                    <group name="1833745452288_2">
                      <mesh
                        name="1833745452288_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["1833745452288_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="HortumBa"
                  position={[0, 1.424, 100.112]}
                  rotation={[0, -1.571, 0]}
                >
                  <group name="1835564868928">
                    <mesh
                      name="1835564868928001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1835564868928001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="K"
                  position={[0, -0.299, 98.068]}
                  rotation={[0, -1.571, 0]}
                >
                  <group name="1833745442352">
                    <mesh
                      name="1833745442352001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745442352001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="Karbon"
                  position={[0, -0.299, 100.456]}
                  rotation={[Math.PI / 2, -Math.PI / 2, 0]}
                >
                  <group name="1841233951424">
                    <mesh
                      name="1841233951424001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1841233951424001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="Kelep"
                  position={[0, -0.299, 96.138]}
                  rotation={[-Math.PI / 2, -0.09, 0]}
                >
                  <group name="1833745462368">
                    <mesh
                      name="1833745462368001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745462368001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="O_ring"
                  position={[0, -0.299, 98.225]}
                  rotation={[Math.PI / 2, 0, 0]}
                >
                  <group name="1833745452288">
                    <mesh
                      name="1833745452288001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745452288001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="pan_head_cross_recess_screw_din_ISO_7045___M6_x_30___Z_____30N"
                  position={[0, 1.568, 102.525]}
                  rotation={[-Math.PI, 1.258, Math.PI / 2]}
                >
                  <group name="1833745472592">
                    <mesh
                      name="1833745472592001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745472592001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="PirincFlans"
                  position={[0, -0.299, 103.18]}
                  rotation={[Math.PI / 2, 1.434, 0]}
                >
                  <group name="1851583791168">
                    <mesh
                      name="1851583791168001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583791168001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="RULMAN"
                  position={[0, -0.299, 103.18]}
                  rotation={[-Math.PI / 2, 0, 0]}
                >
                  <group name="1833745465824">
                    <mesh
                      name="1833745465824001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1833745465824001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="ر30_KELEPǅ"
                  position={[0, -0.299, 101.396]}
                  rotation={[-Math.PI / 2, 0, 0]}
                >
                  <group name="1851583767840">
                    <mesh
                      name="1851583767840001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583767840001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                  <group name="1851583769568">
                    <mesh
                      name="1851583769568001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583769568001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                  <group name="1851583770720">
                    <mesh
                      name="1851583770720001"
                      castShadow
                      receiveShadow
                      geometry={nodes["1851583770720001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                  
                </group>
                
              </group>
          

              <group name="SKAPLIN" position={[-0.506, -0.03, -15.851]}>
                <group
                  name="2564896980912"
                  position={[-1.622, 2.641, 65.1]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                >
                  <Html wrapperClass="anno" position={[-2.4, 1, 0]}>
                    <Tooltip
                      className="opacity-1 visible mr-4"
                      style="light"
                      placement="bottom"
                      content="SIKMALI KAPLİN"
                    >
                      <FaRegCircleDot />
                    </Tooltip>
                  </Html>
                  <group name="SIKMALI_KON݋_KAPL_M10_YAYLI_RONDELA_3">
                    <group
                      name="2562807472800_3"
                      rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562807472800_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562807472800_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896981440"
                  position={[-1.622, 2.641, 62.8]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                >
                  <group name="SIKMALI_KON݋_KAPL_M10_YAYLI_RONDELA_2">
                    <group
                      name="2562807472800_2"
                      rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562807472800_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562807472800_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896981968"
                  position={[3.278, -2.641, 65.1]}
                  rotation={[-2.8, 0, Math.PI / 2]}
                >
                  <group name="SIKMALI_KON_KAPL_4">
                    <group
                      name="2562676051312_4"
                      rotation={[-Math.PI, -0.341, Math.PI / 2]}
                    >
                      <mesh
                        name="2562676051312_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676051312_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896982144"
                  position={[-1.622, -2.641, 65.1]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                >
                  <group name="SIKMALI_KON݋_KAPL_M10_YAYLI_RONDELA_4">
                    <group
                      name="2562807472800_4"
                      rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562807472800_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562807472800_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896982496"
                  position={[-2.842, -2.641, 65.1]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="SIKMALI_KON݋_KAPL_M10x35mm_AKB_SOMUN_4">
                    <group
                      name="2562684128176_4"
                      rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562684128176_4001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562684128176_4001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896983552"
                  position={[3.278, -2.641, 62.8]}
                  rotation={[-2.8, 0, Math.PI / 2]}
                >
                  <group name="SIKMALI_KON_KAPL_3">
                    <group
                      name="2562676051312_3"
                      rotation={[-Math.PI, -0.341, Math.PI / 2]}
                    >
                      <mesh
                        name="2562676051312_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676051312_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896983728"
                  position={[-2.842, 2.641, 62.8]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="SIKMALI_KON݋_KAPL_M10x35mm_AKB_SOMUN_2">
                    <group
                      name="2562684128176_2"
                      rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562684128176_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562684128176_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896984608"
                  position={[-2.842, 2.641, 65.1]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group name="SIKMALI_KON݋_KAPL_M10x35mm_AKB_SOMUN_3">
                    <group
                      name="2562684128176_3"
                      rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                    >
                      <mesh
                        name="2562684128176_3001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562684128176_3001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896985664"
                  position={[3.278, 2.641, 65.1]}
                  rotation={[-0.05, 0, Math.PI / 2]}
                >
                  <group name="SIKMALI_KON_KAPL_2">
                    <group
                      name="2562676051312_2"
                      rotation={[-Math.PI, -0.341, Math.PI / 2]}
                    >
                      <mesh
                        name="2562676051312_2001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2562676051312_2001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896986896"
                  position={[0.528, 4.1, 67.63]}
                  rotation={[0, 0, 0.373]}
                >
                  <group name="2564141281904_8" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_8001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_8001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896987248"
                  position={[0.528, 4.1, 68.85]}
                  rotation={[-Math.PI, 0, 0.553]}
                >
                  <group name="M10_YAYLI_RONDELA_7">
                    <group
                      name="2563933659840_7"
                      rotation={[Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_7001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_7001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896988128"
                  position={[0.528, -4.1, 68.85]}
                  rotation={[Math.PI, 0, -0.976]}
                >
                  <group name="M10_YAYLI_RONDELA_6">
                    <group
                      name="2563933659840_6"
                      rotation={[Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_6001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_6001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896990064"
                  position={[4.628, 0, 68.85]}
                  rotation={[-Math.PI, 0, 0.441]}
                >
                  <group name="M10_YAYLI_RONDELA_8">
                    <group
                      name="2563933659840_8"
                      rotation={[-Math.PI, 0, 0.664]}
                    >
                      <mesh
                        name="2563933659840_8001"
                        castShadow
                        receiveShadow
                        geometry={nodes["2563933659840_8001"].geometry}
                        material={materials.DefaultMaterial}
                      />
                    </group>
                  </group>
                </group>
                <group
                  name="2564896990240"
                  position={[4.628, 0, 67.63]}
                  rotation={[0, 0, 0.273]}
                >
                  <group name="2564141281904_6" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_6001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_6001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="2564896991120"
                  position={[0.528, -4.1, 67.63]}
                  rotation={[0, 0, 0.373]}
                >
                  <group name="2564141281904_7" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_7001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_7001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="M10_YAYLI_RONDELA_5"
                  position={[-3.572, 0, 68.85]}
                  rotation={[-Math.PI, 0, 0.664]}
                >
                  <group name="2563933659840_5" rotation={[Math.PI, 0, 0.664]}>
                    <mesh
                      name="2563933659840_5001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2563933659840_5001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="M10x35mm_AKB_SOMUN"
                  position={[-3.572, 0, 67.63]}
                  rotation={[0, 0, -2.869]}
                >
                  <group name="2564141281904_5" rotation={[0, 0, 2.869]}>
                    <mesh
                      name="2564141281904_5001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2564141281904_5001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="SIKMALI_KON_KAPL"
                  position={[3.278, 2.641, 62.8]}
                  rotation={[-2.8, 0, Math.PI / 2]}
                >
                  <group
                    name="2562676051312"
                    rotation={[-Math.PI, -0.341, Math.PI / 2]}
                  >
                    <mesh
                      name="2562676051312001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2562676051312001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group name="SIKMALI_KONIK" position={[0.528, 0, 69.65]}>
                  <mesh
                    name="SIKMALI_KONIK001"
                    castShadow
                    receiveShadow
                    geometry={nodes.SIKMALI_KONIK001.geometry}
                    material={materials.DefaultMaterial}
                  />
                </group>
                <group
                  name="SIKMALI_KON݋_KAPL_M10_YAYLI_RONDELA"
                  position={[-1.622, -2.641, 62.8]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                >
                  <group
                    name="2562807472800"
                    rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                  >
                    <mesh
                      name="2562807472800001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2562807472800001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
                <group
                  name="SIKMALI_KON݋_KAPL_M10x35mm_AKB_SOMUN"
                  position={[-2.842, -2.641, 62.8]}
                  rotation={[Math.PI / 2, Math.PI / 2, 0]}
                >
                  <group
                    name="2562684128176"
                    rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                  >
                    <mesh
                      name="2562684128176001"
                      castShadow
                      receiveShadow
                      geometry={nodes["2562684128176001"].geometry}
                      material={materials.DefaultMaterial}
                    />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./sactekne_low.glb");
