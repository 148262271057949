import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const products = [
  {
    name: "Sac Tekne Sevk Sistemi",
    to: "/Sac-Tekne-Sevk-Sistemi",
  },
  {
    name: "Ahşap Tekne Sevk Sistemi",
    to: "/Ahsap-Tekne-Sevk-Sistemi",
  },
  {
    name: "Pervane",
    to: "/Pervane",
  },
  {
    name: "Şaft Keçesi",
    to: "/Saft-Kecesi",
  },
  {
    name: "Şaft Körüğü",
    to: "/saft-korugu",
  },
  {
    name: "Şaft Yatağı",
    to: "/Saft-Yatağı",
  },
  {
    name: "Esnek Kaplin",
    to: "/Esnek-Kaplin",
  },
  {
    name: "Sıkma Kaplin",
    to: "/Sikmali-kaplin",
  },
  {
    name: "Sac Tekne Kovan Sistemi",
    to: "/Sac-Tekne-Kovan-Sistemi",
  },
  {
    name: "Ahşap Tekne Kovan sistemi",
    to: "/Ahsap-Tekne-Kovan-Sistemi",
  },
  {
    name: "Paslanmaz Çelik Çubuk",
    to: "/Paslanmaz-Celik-Cubuk",
  },
  {
    name: "Paslanmaz Çelik Çekme",
    to: "/Paslanmaz-Celik-Cekme-Boru",
  },
  {
    name: "Çelik Çekme Boru",
    to: "/Celik-Cekme-Boru",
  },
  {
    name: "Sac Motor Takozu",
    to: "/Sac-Motor-Takozu",
  },
  {
    name: "Alüminyum Motor Takozu",
    to: "/Aluminyum-Motor-Takozu",
  },
  {
    name: "Yanmar Motor Takozu",
    to: "/Yanmar-Motor-Takozu",
  },
  {
    name: "Waterlock",
    to: "/Waterlock",
  },
  
  {
    name: "Deniz Suyu Filtresi",
    to: "/Deniz-Suyu-Filtresi",
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="Header bg-white  absolute w-screen z-[100]">
      <nav
        className="mx-auto flex max-w-7xl h-20 items-center justify-between p-0 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="flex justify-center items-center">
            <span className="sr-only w-24 h-24">Your Company</span>
            <img className="h-24 w-24" src="../hds_logo_color.svg" alt="hds logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        <Popover.Group  className="hidden lg:flex lg:gap-x-12">
        <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
            Ana Sayfa
          </Link>
          <Popover   className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              Ürünler
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 "
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition  ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"

            >
              <Popover.Panel  className="absolute -left-8 top-full z-50 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                    >

                      <div className="flex-auto">
                        <Popover.Button as={Link}
                          to={item.to}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Popover.Button>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <Popover.Button as={Link}
                      key={item.name}
                      to={item.to}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Popover.Button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Link to="/About" className="text-sm font-semibold leading-6 text-gray-900">
            Hakkımızda
          </Link>
          
          <Link to="/Medya" className="text-sm font-semibold leading-6 text-gray-900">
            Medya
          </Link>
          <Link to="/Contact" className="text-sm font-semibold leading-6 text-gray-900">
            İletişim
          </Link>
          <Link to="/Request-Form" className="text-sm font-semibold leading-6 text-gray-900">
            Sipariş Formu
          </Link>
        </Popover.Group>
       <div className="hidden lg:flex lg:flex-1 lg:justify-end">
       {/*   <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
          */}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="../hds_logo_color.svg"
                alt="hds logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
              <Link
                as={Link}
                onClick={() => setMobileMenuOpen(false)}

                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Ana Sayfa
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                    
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Ürünler
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button  onClick={() => setMobileMenuOpen(false)}
                            key={item.name}
                            as={Link}
                            to={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                            
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
         
                onClick={() => setMobileMenuOpen(false)}
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Hakkımızda
                </Link>
                <Link
                                onClick={() => setMobileMenuOpen(false)}

                  to="/medya"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Medya
                </Link>

                <Link
                                onClick={() => setMobileMenuOpen(false)}

                  to="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  İletişim
                </Link>
                <Link
                                onClick={() => setMobileMenuOpen(false)}

                  to="/Request-Form"
                  className="-mx-3 block rounded-lg px-3 py-4 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Sipariş Formu
                </Link>

              </div>
              <div className="py-6">

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
