import React from 'react'

const About = () => {
  return (
<>
<div className="flex flex-col justify-center items-center gap-12">
      <div className="h-[25vh] w-full flex justify-center items-center mt-20">
        <img
          src="/img/web/propeller.webp"
          className="h-full  w-full object-contain "
        ></img>
      </div>

      <div className="w-auto flex flex-col   ">
        <div className="flex max-w-screen-xl  gap-8 px-8 py-16 mx-auto rounded-lg  md:px-12 lg:px-16 xl:px-32 bg-gray-800 text-gray-100">
          <div className="flex flex-col justify-center items-center ">
            <div className="space-y-2  ">
              <h2 className="text-4xl font-bold leadi lg:text-5xl text-center">Hakımızda</h2>
              <div className="text-gray-400 text-center" >Denizcilik sektöründe, farklı amaçlarla kullanılan tüm deniz taşıtlarının sevk sistemleri için yenilikçi çözümler üretme misyonumuzla uzun yıllardır hizmet vermekteyiz.
              Önceliğimiz, müşterilerimizin ihtiyaçlarını, hammaddeden son ürüne kadar kendi bünyemizde üretmektir.</div>
            </div>
            <img
              src="assets/svg/doodle.svg"
              alt=""
              className="p-6 h-52 md:h-64"
            />
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default About