import ReactPlayer from "react-player";

const Medya = () => {
  return (
    <div className="flex flex-col h-full w-full justify-center items-center absolute gap-8  bg-gray-200">
      <div className="w-full">
        <h2 className="text-center text-[#212C42]  text-xl font-bold">Şaft Sevk Sistemi Montaj Animasyonu</h2>
        <div className="relative w-full pb-[56.25%] sm:w-[640px] sm:h-[360px] sm:pb-0 sm:mx-auto">
          <div className="relative w-full h-full overflow-hidden rounded-lg bg-black">
            <ReactPlayer
              controls
              url="https://www.hdsmarin.com/media/0001.mp4"
              width="100%"
              height="100%"
              className="react-player"
            />
          </div>
        </div>
      </div>

      <div className="w-full">
        <h2 className="text-center text-[#212C42]  text-xl font-bold">Şaft Sevk Sistemi Bileşenleri</h2>
        <div className="relative w-full pb-[56.25%] sm:w-[640px] sm:h-[360px] sm:pb-0 sm:mx-auto">
          <div className="relative w-full h-full overflow-hidden rounded-lg bg-black">
            <ReactPlayer
              controls
              url="https://www.hdsmarin.com/media/0002.mp4"
              width="100%"
              height="100%"
              className="react-player"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Medya;
