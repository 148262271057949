import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Pyanmarmotortakoz = () => {
  const tableData = [
    {
      no: 1,
      desc: "ALT GÖVDE",
      brand: "HASÇELİKLER",
      material: "ÇELİK SAC",
      count: "1",
    },
    {
      no: 2,
      desc: "KAUÇUK",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 3,
      desc: "ÜST GÖVDE",
      brand: "HASÇELİKLER",
      material: "ÇELİK SAC",
      count: "1",
    },
    {
      no: 4,
      desc: "ŞAPKALI TABAN SOMUNU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 5,
      desc: "AYAR SOMUNU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "2",
    },
    {
      no: 6,
      desc: "GENİŞ PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 7,
      desc: "YAYLI PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 8,
      desc: "SAPLAMA",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
  ];



  const specData = [
    {
      fields: ["HDS-Y1"," m14*1,5","160","130","80","60","50","30","13"],    
    },
    {
      fields: ["MT1-70","m16*1,5","205","174","80","76","60","30","13"],    
    },
  ];

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-4">
        YANMAR MOTOR TAKOZU
      </div>

      <div className="flex  items-center justify-center lg:max-w-[40rem] ">
        <img
          src="/img/products/yanmar takoz.webp"
          alt="yanmar motor takozu"
          className=""
        />
      </div>
      <div className="py-8 lg:max-w-[33vw] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-4 my-8 lg:max-w-[25rem] m-4 mix-blend-luminosity">
        <img
          src="/img/teknik/yanmar motor takozu teknik.webp"
          alt="yanmar motor takozu"
          className=""
        />
      </div>
      <div className="desc py-8 lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="yanmarmotortakozDesc"/>
      </div>

      <div className="desc py-8 w-full m-8 flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <SpecTable  cols={["TİP", "D", "A", "L", "H1", "H2","B","D1","D2"]} specData={specData} />
      </div>
      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/motorlu takoz yanmar.webp"
          alt="yanmar motor takozu"
          className=""
        />
      </div>
    </div>
    
  );
};

export default Pyanmarmotortakoz;
