import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";

const Pahsapkovsis = () => {
  const tableData = [
    {
      no: 1,
      desc: "KOVAN BORUSU",
      brand: "HASÇELİKLER",
      material: "ÇELİK ÇEKME",
      count: "1",
    },
    {
      no: 2,
      desc: "ARKA GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 3,
      desc: "İÇ GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 4,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 5,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 6,
      desc: "ŞAFT KEÇESİ",
      brand: "HASÇELİKLER",
      material: "PSLNMZ ÇLK+KAUÇUK",
      count: "1",
    },
  ];

  const descData = [
    {
      title: "Sızdırmazlık",
      desc: "Ahşap teknelerde kovan borusu teknenin gövdesine krom civatalarla bağlanır.",
    },
    {
      title: "Koruyucu",
      desc: "Kovan borusunun uzun ömürlü olması için et kalınlığı 4mm ve üzeri çekme paslanmaz çelik borular tercih edilir.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Teknenin gövdesine kolay montaj yapılması için bronz yataklar geniş flanşlı olarak dökülüp hazırlanır.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Şaft yatakları aşındığında bronz yataklar kovan borusundan sökülerek değiştirilebilir.",
    },
  ];

  return (
    <div className="overflow-hidden min-h-screen h-auto w-full relative z-50 top-20 bg-gray-200 flex flex-col justify-center items-center">
      {/* Başlık */}
      <div className="text-3xl sm:text-4xl md:text-5xl xl:text-[4vmax] font-black tracking-wider text-[#212C42] p-4  absolute top-0 w-full">
        AHŞAP TEKNE KOVAN SİSTEMİ
      </div>

      {/* İçerik: Görsel ve Tablo */}
      <div className="flex flex-col xl:flex-row xl:items-center xl:justify-around w-full p-4 gap-12 mt-32">
        {/* Görsel */}
        <div className="flex items-center justify-center w-full xl:max-w-[30%]">
          <img
            src="/img/products/ahsap kovan.webp"
            alt="ahşap tekne kovan sistemi"
            className="w-full max-w-sm md:max-w-md xl:max-w-lg"
          />
        </div>

        {/* Tablo */}
        <div className="w-full xl:max-w-[30%] flex justify-center items-center">
          <ComponentTableImg tableData={tableData} />
        </div>
      </div>

      {/* Açıklama */}
      <div className="desc px-4 my-8 w-full xl:max-w-[35%] text-center xl:text-left flex justify-center items-center mt-8">
        <ProductDescCard descData={descData} />
      </div>
    </div>
  );
};

export default Pahsapkovsis;
