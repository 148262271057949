import React from "react";

export default function ComponentTableImg({ tableData }) {
 // console.log();
  return (
    <div className="ComponentTableImg  overflow-hidden min-w-[30vw] mx-auto">
      {/* Table */}
      <div className=" text-xs md:text-lg mx-auto  shadow-lg rounded-sm  border-gray-200">
        <header className="px-5 flex items-center justify-center h-8 md:h-10 border-b bg-[#212C42] border-gray-100">
          <h2 className="text-center  font-bold  text-white">
            ÜRÜN BİLEŞEN TABLOSU
          </h2>
        </header>
        <div className="">
          <div className="">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                <tr>
                  {tableData[0].no !== undefined ? (
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">NO</div>
                    </th>
                  ) :       <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left"></div>
                    </th>}

                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">BİLEŞEN</div>
                  </th>

                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">MARKA</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">MALZEME</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">ADET</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs md:text-sm divide-y divide-gray-100">
                {tableData.map((item, i) => (
                  <tr key={i}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="h-6 md:h-12 flex-shrink-0 mr-2 sm:mr-3">
                          {tableData[0].img !== undefined ? (
                            <img
                              className="rounded-full mx-auto h-full"
                              src={item.img}
                              width={40}
                              height={40}
                              alt=""
                            />
                          ) : (
                            <div>{item.no}</div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      <div className="font-medium text-left">{item.desc}</div>
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      <div className="text-left font-medium ">{item.brand}</div>
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      <div className="font-medium  text-center">
                        {item.material}
                      </div>
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      <div className="font-medium  text-center">
                        {item.count}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
