import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";



const Pahsapsevk = () => {

  const tableData = [
    {
      no: 1,
      desc: "PERVANE",
      brand: "HASÇELİKLER",
      material: "PİRİNÇ",
      count: "1",
    },
    {
      no: 2,
      desc: "ŞAFT",
      brand: "HASÇELİKLER",
      material: "KROM",
      count: "1",
    },
    {
      no: 3,
      desc: "ARKA GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 4,
      desc: "KOVAN BORUSU",
      brand: "HASÇELİKLER",
      material: "KROM",
      count: "1",
    },
    {
      no: 5,
      desc: "ÖN GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 6,
      desc: "YATAK",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 7,
      desc: "ŞAFT KEÇESİ",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 8,
      desc: "SIKMALI KAPLİN",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 9,
      desc: "ESNEK KAPLİN",
      brand: "HASÇELİKLER",
      material: "TPU",
      count: "1",
    },

  ];

  return (
    <div className="overflow-hidden h-full w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-start justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen px-4">
        AHŞAP TEKNE SEVK SİSTEMİ
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vw]   -mt-20 ">
        <img
          src="/img/products/ahsap tekne sevk.webp"
          alt="ahşap tekne sevk sistemi"
          className=""
        />
      </div>




      <div className="lg:max-w-[30vw] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>
    </div>
    
  );
};

export default Pahsapsevk;
