import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Psacmotortakoz = () => {
  const tableData = [
    {
      no: 1,
      desc: "ALT GÖVDE",
      brand: "HASÇELİKLER",
      material: "ÇELİK SAC",
      count: "1",
    },
    {
      no: 2,
      desc: "KAUÇUK",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 3,
      desc: "ÜST GÖVDE",
      brand: "HASÇELİKLER",
      material: "ÇELİ SAC",
      count: "1",
    },
    {
      no: 4,
      desc: "ŞAPKALI TABAN SOMUNU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 5,
      desc: "AYAR SOMUNU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "2",
    },
    {
      no: 6,
      desc: "GENİŞ PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 7,
      desc: "YAYLI PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 8,
      desc: "SAPLAMA",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
  ];



  const specData = [
    {
      fields: ["ST1-45","100","120","60","38","90","14","11","M12","60"],    
    },
    {
      fields: ["ST1-55","100","120","60","38","90","14","11","M14","70"],    
    },
    {
      fields: ["ST1-65","100","120","60","38","90","14","11","M16","110"],    
    },
    {
      fields: ["ST2-45","140","183","75","50","90","30","13","M16","160"],    
    },
    {
      fields: ["ST2-55","140","183","75","50","90","30","13","M20","220"],    
    },
    {
      fields: ["ST2-65","140","183","75","50","90","30","13","M22","310"],    
    },
    {
      fields: ["ST3-45","182","230","70","2″","100","39","18","M20","350"],    
    },
    {
      fields: ["ST3-55","182","230","70","2,1/4″","100","39","18","M24","550"],    
    },
    {
      fields: ["ST3-65","182","230","70","2,1/2″","100","39","18","M30","810"],    
    },
  ];

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        SAC MOTOR TAKOZU
      </div>

      <div className="flex  items-center justify-center lg:max-w-[40rem]  ">
        <img
          src="/img/products/Motor takoz.webp"
          alt="sac motor takozu"
          className=""
        />
      </div>
      <div className="lg:max-w-[30rem] lg:pr-8 py-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-4 my-8 lg:max-w-[40rem] m-4  mix-blend-luminosity">
        <img
          src="/img/teknik/sac motor takozu teknik.webp"
          alt="sac motor takozu"
          className=""
        />
      </div>
      <div className="desc py-8 lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="sacmotortakozDesc"/>      </div>

      <div className="desc py-8 w-full m-8 flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <SpecTable  cols={["TİP", "A", "L", "B", "H2", "H1","D1","D2","D","MAX KG"]} specData={specData} />
      </div>
      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/motorlu takoz sac.webp"
          alt="sac motor takozu"
          className=""
        />
      </div>
    </div>
    
  );
};

export default Psacmotortakoz;
